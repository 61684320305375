import { afterNextRender, ChangeDetectionStrategy, Component, Input, OnInit, runInInjectionContext } from '@angular/core';
import { RouterLink } from '@angular/router';

import { ButtonTypeEnum } from '@models/enums';

import { ButtonComponent } from '@components/common/_buttons/button/button.component';
import { LinkComponent } from '@components/common/link/link.component';
import { UrlFixedPipe } from '../../../../pipes/url-fixed/url-fixed.pipe';
import { HyphenatePipe } from '../../../../pipes/hyphenate/hyphenate.pipe';

@Component({
  standalone: true,
  selector: 'sps-button-link',
  templateUrl: './button-link.component.html',
  styleUrls: ['./button-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterLink, ButtonComponent, UrlFixedPipe, HyphenatePipe],
})
export class ButtonLinkComponent extends LinkComponent implements OnInit {
  @Input({ required: true }) label: string = null;

  @Input() set buttonType(buttonType: ButtonTypeEnum) {
    this._buttonType = buttonType || ButtonTypeEnum.PRIMARY;
    this.customize();
  }

  private _buttonType: ButtonTypeEnum = ButtonTypeEnum.PRIMARY;

  get buttonType(): ButtonTypeEnum {
    return this._buttonType;
  }

  ngOnInit() {
    this.customize();
  }

  private customize(): void {
    runInInjectionContext(this.injector, () => {
      afterNextRender(() => {
        const link = this.el.nativeElement.querySelector('a');

        if (!link) {
          return;
        }

        Object.values(ButtonTypeEnum).forEach(attr => link.removeAttribute(attr));
        link.setAttribute(this.buttonType, this.buttonType);
        this.el.nativeElement.setAttribute('data-button-type', this.buttonType);

        this.cd.markForCheck();
      });
    });
  }
}
